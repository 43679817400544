import classes from './DictionaryItemMeaning.module.css';
import React from "react";
import Card from "../ui/Card";

const DictionaryItemMeaning = ({partOfSpeech, definitions}) => {
    return (
        <Card customStyle={classes.container}>
            <h3 className={classes.capitalize}>{partOfSpeech}:</h3>
            {definitions.map((definition, index) => {
                return (
                    <div
                        style={{
                            paddingTop: '5px',
                            paddingBottom: '5px'
                        }}
                        key={index}
                    >
                        <p>Definition: {definition.definition}</p>
                        {definition.example && <p>Example: {definition.example}</p>}
                    </div>
                )
            })}
        </Card>
    )
};

export default DictionaryItemMeaning;