import './App.css';
import {Fragment, useEffect, useState} from "react";
import Card from "./components/ui/Card";
import DictionaryItem from "./components/dictionary/DictionaryItem";
import Search from "./components/search/Search";
import Logo from './images/logo_transparent.png';
import { useMatomo } from '@datapunt/matomo-tracker-react'

const App = () => {
    const [audioError, setAudioError] = useState(false);
    const [words, setWords] = useState([]);
    const [search, setSearch] = useState('');
    const [error, setError] = useState({
        bool: false,
        message: 'Please query a word.'
    });
    const [firstPageLoad, setFirstPageLoad] = useState(true);
    const [message, setMessage] = useState('');
    const url = 'https://api.dictionaryapi.dev/api/v2/entries/en/';

    const { trackPageView, trackSiteSearch, trackEvent } = useMatomo();

    const getWord = async search => {
        try {
            const response = await fetch(url+search);
            if(response.status===404){
                throw new Error("No Definitions Found");
            } else if(!response.ok){
                throw new Error("There has been some error");
            }
            const data = await response.json();
            trackSiteSearch({
                keyword: search,
                category: false,
                searchCount: false
            })
            setError({
                bool: false,
                message: ''
            })
            setWords(data);
        } catch (error) {
            setError({
                bool: true,
                message: String(error.message)
            });
            trackEvent({
                category: 'Search Query',
                action: 'Invalid',
                name: `Word: ${search}`
            });
            setWords([])
        }
    }

    useEffect(()=>{
        if(firstPageLoad){
            setFirstPageLoad(false);
            const searchQuery = window.location.search;
            const params = new URLSearchParams(searchQuery);
            const getSearchParam = params.get('search');
            if(getSearchParam){
                setSearch(getSearchParam);
            } else {
                trackPageView({
                    documentTitle: 'ZedPro English Dictionary', // optional
                    href: 'https://zdict.demo.dev.zedpro.me', // optional
                })
            }
        }
    }, [])

    useEffect(()=>{
        setAudioError(false);
        if(search.length>0){
            const timer = setTimeout(()=> {
                getWord(search);
            }, 500);
            return () => {
                clearTimeout(timer)
            }
        } else if(search.length===0) {
            setError({
                bool: false,
                message: ''
            })
            setWords([]);
        }
    },[search])
    useEffect(() => {
        if (error.bool) {
            setMessage(error.message);
        }
        if (!error.bool) {
            setMessage('Please query a word.')
        }
    }, [error])
    return (
        <Fragment>
            <div className="area">
                <ul className="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
            <div className="context">
                <div className='header'>
                    <a href="https://zedpro.me">
                        <img className='logo' alt="logo" src={Logo}/>
                    </a>
                    <a href="https://zedpro.me">
                        <h1>English Dictionary</h1>
                    </a>
                </div>
                <Search
                    search={search}
                    setSearch={setSearch}
                    getWord={getWord}
                />
                {
                    words.length>0 ?
                    words.map((word, index) => {
                            return (
                                <DictionaryItem
                                    audioError={audioError}
                                    setAudioError={setAudioError}
                                    key={index}
                                    word={word.word}
                                    phonetic={word.phonetic}
                                    phonetics={word.phonetics}
                                    origin={word.origin}
                                    meanings={word.meanings}
                                />
                            )
                        })
                    :
                    <Card>
                    <h3 className='text'>{message}</h3>
                    </Card>
                }
            </div>
        </Fragment>
    );
}

export default App;
