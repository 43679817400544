import classes from './Search.module.css';
import React from "react";
import SearchIcon from '../../images/search.svg';

const Search = ({search, setSearch, getWord}) => {
    return (
            <div className={classes.search}>
                <input
                    onChange={e => {
                        setSearch(e.target.value);
                    }}
                    placeholder='Search for words'
                    value={search}
                />
                <img
                    onClick={()=>getWord(search)}
                    src={SearchIcon}
                    alt="Search"
                />
            </div>
    )
}

export default Search;