import classes from './DictionaryItem.module.css';
import React, {useState} from "react";
import Card from "../ui/Card";
import DictionaryItemMeaning from "./DictionaryItemMeaning";
import { useMatomo } from '@datapunt/matomo-tracker-react'

import { FaFileAudio } from 'react-icons/fa';
import LoadingSpinner from "../ui/LoadingSpinner";

const DictionaryItem = ({word, phonetic, phonetics, origin, meanings, audioError, setAudioError}) => {
    const { trackEvent } = useMatomo();
    const [isLoading, setIsLoading] = useState(false);
    const errorMessage = "The audio file could not be loaded"
    const audioURL = phonetics.filter(audioObject => {
        return audioObject.audio!==''
    })
    const playHandler = async () => {
        if(!isLoading){
            trackEvent({
                category: 'User Action',
                action: 'Audio Playback',
                name: `Word: ${word}`
            })
            try {
                setIsLoading(true);
                const audio = new Audio(audioURL[0].audio);
                await audio.play();
                setIsLoading(false);
            } catch (error) {
                setAudioError(true)
                setIsLoading(false);
            }
        }
    }
    return (
        <Card customStyle={classes.container}>
            <h3>Queried Word:</h3>
            <h2>{word}</h2>
            <div className={classes.flex}>
                {audioURL.length>0 &&
                    <FaFileAudio
                        size={30}
                        onClick={playHandler}
                        className={`${classes['flex-item']} ${classes['audio-icon']} ${isLoading&&classes['audio-icon-active']}`}
                    />}
                {isLoading && <LoadingSpinner customClass={classes['flex-item']}/>}
                {phonetic &&
                    <p className={classes['flex-item']}>
                        Phonetic: {phonetic}
                    </p>}
                {audioError &&
                    <p
                        style={{
                            color: 'red',
                            marginLeft: 'auto'
                    }}
                        className={classes['flex-item']}
                    >
                        {errorMessage}
                    </p>}
            </div>
            {origin && <p>Origin: {origin}</p>}
            {meanings.map((meaning, index) => {
                return (
                    <DictionaryItemMeaning
                        key={index}
                        partOfSpeech={meaning.partOfSpeech}
                        definitions={meaning.definitions}
                    />
                )
            })}
        </Card>
    );
};

export default DictionaryItem;