import React from 'react';
import classes from './Card.module.css';

const Card = ({children, customStyle}) => {
    return (
        <div className={`${classes.card} ${customStyle && customStyle}`}>
            {children}
        </div>
    )
};

export default Card;