import React from "react";
import classes from './LoadingSpinner.module.css';

const LoadingSpinner = ({customClass}) => {
    return (
        <div className={customClass}>
            <div className={classes['loading-spinner']}>
            </div>
        </div>
    );
}

export default LoadingSpinner